import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Edicao = loadable(() => import('@screens/postar_plantao/Edicao'));

function Index(props) {
  return (
    <Layout {...props}>
      <Edicao {...props} />
    </Layout>
  );
}

export default Index;
